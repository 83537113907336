import * as React from "react"
import Header from "../components/Header";
import {SEO} from "../components/seo";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import { INLINES, MARKS } from '@contentful/rich-text-types'
import Footer from "../components/Footer";


const SecurityPage = ({data}) => {
    const content = data.allContentfulPage.edges[0].node.sections[0].body;
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
        },
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} className="underline">
                        {children}
                    </a>
                )
            },
        },
    }

    return (
        <div>
            <Helmet>
                <title>Inter Protocol - Security</title>
            </Helmet>
            <Header/>
            <div className={'security-page'}>
                <div className={'section content-section'}>
                    <div className={'container container-slim'}>
                        {renderRichText(content, options)}
                    </div>
                </div>
                <div className={'section footer-section'}>
                    <Footer/>
                </div>
            </div>

        </div>
    )
}

export default SecurityPage

export const Head = () => (
    <SEO />
)

export const query = graphql`
  {
    allContentfulPage(filter: {id: {eq: "37a2eda9-6133-5331-85b6-516b2bfbe864"}}) {
      edges {
        node {
          id
          sections {
            ... on ContentfulSimpleTextPageSection {
              body {
                raw
              }
            }
          }
        }
      }
    }
  }
`
